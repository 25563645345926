import React from "react";

import classRoom from "../../../resources/school/classroom.png";
import clapping from "../../../resources/events/clapping.png";
import vr from "../../../resources/events/vr.png";
import tent from "../../../resources/events/tent.png";
import noodles from "../../../resources/events/noodles.png";
import Card from "../../common/Card";
import Checkmark from "../../common/Checkmark";

const Camp = () => {
  return (
    <div className="App">
      <div className="tug-of-war bg-cover bg-center items-center">
        <div className="text-white text-4xl px-28 pt-28 pb-14">
          探索千年中华文化&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;畅享多彩夏令营
        </div>
        <div className="flex justify-evenly h-full items-start">
          <p className="text-white text-xl w-1/4 font-bold text-left">
            在我们的中文夏令营，孩子们将踏上一场探索中国文化的奇妙之旅。
            <br /> <br />
            一个个精心设计、丰富多样的活动，让学生们在领略到中国文化的博大精深的同时，与西方现代化的生活相结合，让古老的文化在现代生活中绽放光彩，交相辉映。
          </p>
          <Card className="bg-white bg-opacity-70 w-1/3">
            <ul className="space-y-1">
              <li className="flex items-center">
                <p className="ml-4">夏令营日期： 待定</p>
              </li>
              <li className="flex items-center">
                <p className="ml-4">时间：9：00AM~5：00PM</p>
              </li>
              <li className="flex items-center">
                <p className="ml-4">营员要求：5-13岁，可以听懂中文</p>
              </li>
              <li className="flex items-center">
                <p className="ml-4 mb-4">
                  地址：11431 NE 20th St, Bellevue, WA 98004
                </p>
              </li>
            </ul>
            {/* <ul className='flex items-start'>
              <li>夏令营日期： 待定</li>
              <li>时间：9：00AM~5：00PM</li>
              <li>营员要求：5-13岁，可以听懂中文</li>
              <li>地址：11431 NE 20th St, Bellevue, WA 98004</li>
            </ul> */}
            <div className="bg-orange-400 rounded-full text-white py-2 px-12">
              报名夏令营
            </div>
          </Card>
        </div>
      </div>

      <div className="p-12 text-4xl text-emerald-500 font-bold bg-green-50">
        有趣，有料，有内涵
      </div>
      <div className="bg-green-50 px-12">
        <ul className="space-y-4">
          <li className="flex items-center">
            <Checkmark />
            <p className="ml-4">
              中文读书，写字，有专业的老师引导孩子们一起研习，探究，提问，成就
            </p>
          </li>
          <li className="flex items-center">
            <Checkmark />
            <p className="ml-4">
              邀请不同的科学家或技术专家，给孩子们展示最前沿的科技领域
            </p>
          </li>
          <li className="flex items-center">
            <Checkmark />
            <p className="ml-4">
              有丰富的体育锻炼和室外活动，有学有玩，学而不倦
            </p>
          </li>
        </ul>
      </div>

      <div className="space-y-16 m-16">
        <div className="flex justify-center space-x-16">
          <img
            src={classRoom}
            alt="img not found"
            className="w-1/2 rounded-xl"
          ></img>
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-left">
              文化之旅
            </div>
            <div className="text-md text-left">
              在夏令营中孩子们了解中国文化的深厚，学习传统艺术，古老传说，感受中华传统文化的独特魅力。通过互动活动，他们深入了解中国的历史，民俗和传统价值观。
            </div>
          </div>
        </div>
        <div className="flex justify-center space-x-16">
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-left">
              文化之旅
            </div>
            <div className="text-md text-left">
              我们注重培养孩子们的中文语言能力，通过有趣的活动和游戏，他们将不知不觉地提高中文表达能力。无论是口语交流还是书写表达，都将在轻松愉快的氛围中得到提升。
            </div>
          </div>
          <img
            src={clapping}
            alt="img not found"
            className="w-1/2 rounded-xl"
          ></img>
        </div>
        <div className="flex justify-center space-x-16">
          <img src={vr} alt="img not found" className="w-1/2 rounded-xl"></img>
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-left">
              科技与创新
            </div>
            <div className="text-md text-left">
              夏令营不仅关注传统文化，还紧跟科技潮流，开阔学生视野。我们引入最新科技，开展有趣的科技体验活动，让孩子们在探索传统的同时，也感受到现代科技的魅力。
            </div>
          </div>
        </div>
        <div className="flex justify-center space-x-16">
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-left">
              丰富的课外活动
            </div>
            <div className="text-md text-left">
              除了学术课程，我们还安排了多样化的课外活动，包括体育运动、艺术创作等，让孩子们在快乐中发展兴趣爱好，培养团队合作精神。
            </div>
          </div>
          <img
            src={tent}
            alt="img not found"
            className="w-1/2 rounded-xl"
          ></img>
        </div>
        <div className="flex justify-center space-x-16">
          <img
            src={noodles}
            alt="img not found"
            className="w-1/2 rounded-xl"
          ></img>
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-left">
              美食之旅，味蕾盛宴
            </div>
            <div className="text-md text-left">
              我们会为孩子们提供健康卫生、丰富多样、营养均衡的世界各地美食。
              <br />
              <br />
              除此之外，我们还将带领孩子们踏上一场美食探险之旅。在品味各地美食的同时，深入探讨这些美食背后的故事。
              <br />
              <br />
              孩子们将有机会亲自参与，学习制作美食，体验烹饪的乐趣，培养创造力和团队合作精神。
              <br />
              <br />
              这不仅是一场味觉的冒险，更是古老烹饪智慧与现代创意的完美结合，让孩子们在美食的世界中汲取无尽的知识，探索不尽的可能性。
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Camp;
