import React from 'react';

import qrCode from '../../resources/school/qrCode.jpg';
import doubleDown from '../../resources/assets/Double Down.png';

const Footer = () => {
    return (
        <div>
            <div className='bg-gray-100 p-10 space-y-8'>
                <img className='mx-auto' src={doubleDown} alt='img not found' style={{ width: '50px' }} />
                <div className="flex justify-center">
                    <span className="bg-emerald-600 text-center text-white p-4">联系我们，即刻开始一场别开生面的中文学习之旅！</span>
                </div>
            </div>
            <div className='footer bg-green-800 text-white py-10'>
                <div className="flex justify-evenly items-start">
                    <div className="space-y-4">
                        <div className="text-left text-2xl font-bold">联系我们</div>
                        <div className="border-t-8 rounded-full border-white/50 w-1/5"></div>
                        <div className="text-left">
                            咨询邮箱：<br />
                            <a href="mailto:admission@gechineseschool.com" className="hover:underline">admission@gechineseschool.com</a><br /><br />
                            西雅图校区地址：<br />
                            11431 NE 20th St,<br />
                            Bellevue, WA 98004
                        </div>
                    </div>
                    <div className="space-y-4">
                        <div className="text-left text-2xl font-bold">了解我们</div>
                        <div className="border-t-8 rounded-full border-white/50 w-1/2"></div>
                        <ul className="text-left space-y-4">
                            <li>课程体系</li>
                            <li>教师团队</li>
                            <li>校园风光</li>
                            <li>加入我们</li>
                        </ul>
                    </div>
                    <div className="space-y-4">
                        <div className="text-left text-2xl font-bold">关注我们</div>
                        <div className="border-t-8 rounded-full border-white/50 w-1/3"></div>
                        <img src={qrCode} alt="QR Code" className="mt-2" style={{ width: "100px" }} />
                        <div className="text-left">
                            “扫一扫”<br />
                            关注校园官方公众号<br />
                            {/* Make sure to replace 'path-to-your-qr-code.png' with the actual path to your QR code image */}
                        </div>
                    </div>
                </div>
                <div className="mt-4 py-4 flex justify-center">
                    <div className="border-t-2 border-white/50 w-2/3 mx-auto"></div>
                </div>
                <p className="text-center text-xs">© 2016-2023 by Ge Chinese LLC. All rights reserved.</p>
            </div>
        </div>

    )
}

export default Footer;