import React from "react";

type RowProps = {
  children: React.ReactNode;
  spaceClass?: string;
};

const Row: React.FC<RowProps> = ({ children, spaceClass }) => {
  return (
    <div
      className={`max-w-md mx-auto overflow-hidden md:flex md:justify-evenly md:max-w-fit ${spaceClass}`}
    >
      {children}
    </div>
  );
};

export default Row;
