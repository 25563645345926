import React from "react";

import icon1 from "../../../resources/assets/icon1.png";
import icon2 from "../../../resources/assets/icon2.png";
import icon3 from "../../../resources/assets/icon3.png";
import icon4 from "../../../resources/assets/icon4.png";
import Form from "./Form";

const ContactUs = () => {
  return (
    <div className="bg-white rounded-xl overflow-hidden m-8 text-center space-y-4">
      <div className="bg-emerald-600 p-4 text-white text-sm">
        联系我们，即刻开始一场别开生面的中文学习之旅！
      </div>
      <div className="text-sm">预约后，您的孩子将获得</div>
      <div className="mx-auto w-4/5 flex justify-evenly">
        <div className="pb-2 px-2 rounded-xl border-2 border-emerald-600">
          <img src={icon1} alt="graph" />
          <div className="text-xs text-emerald-600">
            阶段性
            <br />
            中文测评
          </div>
        </div>
        <div className="pb-2 px-2 rounded-xl border-2 border-emerald-600">
          <img src={icon2} alt="graph" />
          <div className="text-xs text-emerald-600">
            实时
            <br />
            教师答疑
          </div>
        </div>
        <div className="pb-2 px-2 rounded-xl border-2 border-emerald-600">
          <img src={icon3} alt="graph" />
          <div className="text-xs text-emerald-600">
            每周
            <br />
            童书领读
          </div>
        </div>
        <div className="pb-2 px-2 rounded-xl border-2 border-emerald-600">
          <img src={icon4} alt="graph" />
          <div className="text-xs text-emerald-600">
            海量
            <br />
            拓展资料
          </div>
        </div>
      </div>

      <Form />

      <p className="ml-4 text-xs text-gray-400">
        GE Chinese
        <code className="font-bold text-emerald-400"> 保障</code>{" "}
        每位用户的个人信息
      </p>
    </div>
  );
};

export default ContactUs;
