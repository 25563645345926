import React, { useEffect, useState } from "react";

import Testimonials from "./Testimonials";
import Certificates from "./Certificates";

import ap from "../../../resources/feedback/ap.png";
import app from "../../../resources/school/app.png";
import apTestimonials from "../../../resources/feedback/apTestimonials.png";
import classImg from "../../../resources/school/class.png";
import costume from "../../../resources/events/costume.png";

import promise1 from "../../../resources/promises/refund-promise.png";
import promise2 from "../../../resources/promises/check-promise.png";
import promise3 from "../../../resources/promises/growth-promise.png";
import testGroup1 from "../../../resources/feedback/testGroup1.png";
import testGroup2 from "../../../resources/feedback/testGroup2.png";
import testGroup3 from "../../../resources/feedback/testGroup3.png";
import Introduction from "./Introduction";
import ContactUs from "./ContactUs";
import ImageCard from "../../common/ImageCard";
import Row from "../../layout/Row";

const Main = () => {
  const [activeButton, setActiveButton] = useState(1);

  const testImages = [testGroup1, testGroup2, testGroup3];

  const handleButtonClick = (buttonIndex: number) => {
    // Set the active button index
    setActiveButton(buttonIndex);

    // Scroll to the corresponding image group
    const imageGroup = document.getElementById(`imageGroup${buttonIndex}`);
    if (imageGroup) {
      imageGroup.scrollIntoView({ behavior: "smooth", block: "center" }); // Add block property
    }
  };

  return (
    <div>
      <div className="bg-emerald-500 h-50">
        <Row>
          <>
            <Introduction />
            <ContactUs />
          </>
        </Row>
      </div>
      <div className="bg-gray-100 text-center py-4">
        <div className="text-2xl text-emerald-400 py-4 font-bold">教学理念</div>
        <div className="text-sm py-4">
          自极易中文学校创办以来，践行“科技让教育更高效”的教学理念，
          <br />
          用最前沿的科学技术、最先进的教学思想，
          <br />
          帮助海外学子有效学习，高效学习、培养他们成为有根、有自信的世界人。
        </div>
      </div>
      <div className="text-center my-8">
        <div className="text-2xl text-emerald-400 font-bold mb-8">学校特色</div>
        <div className="md:w-3/4 md:mx-auto">
          <Row>
            <>
              <ImageCard
                src={classImg}
                children={
                  <>
                    高效课堂 <br />
                    贴合海外中文学习
                  </>
                }
              />
              <ImageCard
                src={app}
                children={
                  <>
                    AI
                    <br />
                    数据驱动的软件作业
                  </>
                }
              />

              <ImageCard
                src={costume}
                children={
                  <>
                    学校活动丰富
                    <br />
                    助力学生全面发展
                  </>
                }
              />
            </>
          </Row>
        </div>
      </div>

      <Testimonials />

      {/* <Testimonials /> */}
      {/* <ImageGallery images={testImages}/> */}

      <div className="text-center py-4">
        <div className="text-2xl text-emerald-400 py-4 font-bold">
          AP考试家长反馈
        </div>
        <div className="flex justify-center text-lg space-x-20">
          <p className="w-1/2 text-left">
            在美国高中，大部分孩子都会进行AP (Advanced Placement)考试,
            中文AP考试是检验学生中文水平的重要标志之一，也为申请大学提供竞争优势
            <br />
            <br />
            极易学生AP的
            <code className="font-bold text-emerald-600">
              {" "}
              满分5分率历年都在90%以上，通过率100%
            </code>{" "}
            。<br />
            家长对我们的实力更是无比信任。
          </p>
          <img className="w-1/5" src={ap} alt="ap students" />
        </div>
        <img
          className="mx-auto"
          src={apTestimonials}
          alt="ap studentsap testimonals"
        />
      </div>

      <Certificates />

      <div className="text-center py-4 md:w-3/4 m-auto">
        <div className="text-2xl text-emerald-400 py-4 font-bold">
          我们的承诺
        </div>
        <Row>
          <div className="p-4">
            <img className="w-full" src={promise1} alt="img not found"></img>
          </div>
          <div className="p-4">
            <img className="w-full" src={promise2} alt="img not found"></img>
          </div>
          <div className="p-4">
            <img className="w-full" src={promise3} alt="img not found"></img>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default Main;
