import React, { useState, useEffect } from 'react';


const ButtonSwitch = () => {
    const [activeButton, setActiveButton] = useState(1);

    const buttonTexts = [(<div><div className='text-sm'>4-6岁</div>中文启蒙班</div>), (<div><div className='text-sm'>5-16岁</div>中文进阶班</div>), (<div><div className='text-sm'>14-6岁</div>AP中文考试冲刺班</div>), (<div><div className='text-sm'>12-6岁</div>中国文化巡礼班</div>)];
    const texts = [
        (
            <div className='w-3/4 mx-auto border-2 border-emerald-400 rounded-xl bg-gray-100 text-left p-8 m-4'>
                <div className='text-emerald-400 text-lg'>场景学习，贴近生活，激发潜能，自在成长。</div>
                <div>
                    启蒙班的课堂，将会通过歌曲、诗歌、配图、游戏等多种方式，将中文融入到海外儿童的生活场景中，让4~6岁正处在语言认知发展黄金期的孩子，最大化地释放他们的语言潜能，增强他们的中文表达力。<br /><br />
                    学习计划：<br /><br />
                    1. 短文或儿歌的学习与诵读，增强语感与听说能力<br />
                    2. 通过课堂互动活动，培养语言交际和运用能力<br />
                    3. 通过主题相关的拓展练习，提高语言文化感知力和理解力<br />
                    4. 通过诵读儿歌、儿童诗和浅近的古诗，展开想象，获得初步的情感体验，感受语言的优美<br />
                </div>
            </div>
        ),
        (
            <div className='w-3/4 mx-auto border-2 border-emerald-400 rounded-xl bg-gray-100 text-left p-8 m-4'>
                <div className='text-emerald-400 text-lg'>专为海外华裔学子量身打造的中文学习方案</div>
                <div>
                    在扎实训练字、词、句、文的基础上，全面培养学生的中文听说读写能力。在培养和丰富语言积累的同时，能够认识中华文化的丰厚博大，汲取民族优秀文化智慧。培养学生独立阅读能力，开阔视野，激发潜能。尊重世界多样文化，吸收人类优秀文化的营养。使用《马立平中文》教材。<br /><br />
                    学习计划：<br /><br />
                    1. 认识并熟记常用汉字 （六个月识字量300字， 6年级累积认识常用汉字3000个左右）<br />
                    2. 学习使用普通话正确、流利、有感情地朗读文章<br />
                    3. 学会汉语拼音。能读准声母、韵母、声调和整体认读音节。能准确地拼读音节<br />
                    4. 学会基本的书写， 了解汉字的间架结构特点，感受到汉字的形体美<br />
                    5. 学会打字，可以使用计算机进行中文语言的输出，文从字顺地用中文书面语表达自己的观点<br />
                    6. 具有独立阅读能力，喜欢阅读，并能感受到阅读的乐趣<br />
                    7. 具有日常中文口语交际的能力，学会倾听、并能进行有自信的表达与交流<br />
                </div>
            </div>
        ),
        (
            <div className='w-3/4 mx-auto border-2 border-emerald-400 rounded-xl bg-gray-100 text-left p-8 m-4'>
                <div className='text-emerald-400 text-lg'>在系统的中文学习后，帮助学生在九年级至十一年级期间通过美国大学理事会（College Board）主办的中文AP考试，并取得通过率100%的考试成绩。</div>
                <div>
                    学习内容：<br /><br />
                    1. 认识并熟记常用汉字 （六个月识字量300字， 6年级累积认识常用汉字3000个左右）
                    <ul className='list-disc'>
                        <li>提高听力和阅读理解能力，使学生能够听懂各种生活场景下的中文，阅读各种类型的中文文章</li>
                        <li>加强口语和写作技能，使学生能够流利地进行口语交流，写出连贯、准确的文章</li>
                    </ul>
                    2. 学习使用普通话正确、流利、有感情地朗读文章<br />
                    <ul className='list-disc'>
                        <li>了解中国文化、历史、传统和现代社会，培养学生对中国文化的兴趣和理解</li>
                        <li>学习中国节日、习俗、艺术等文化元素，拓宽学生的文化视野</li>
                    </ul>
                    3. 学会汉语拼音。能读准声母、韵母、声调和整体认读音节。能准确地拼读音节<br />
                    <ul className='list-disc'>
                        <li>针对AP考试的题型和要求进行针对性培训，帮助学生熟悉考试内容和答题技巧</li>
                    </ul>
                    4. 学会基本的书写， 了解汉字的间架结构特点，感受到汉字的形体美<br />
                    <ul className='list-disc'>
                        <li>培养学生具备在不同文化背景下进行交流的能力，尊重和理解不同文化之间的差异，具备跨文化沟通的能力</li>
                    </ul>
                </div>
            </div>
        ),
        (
            <div className='w-3/4 mx-auto border-2 border-emerald-400 rounded-xl bg-gray-100 text-left p-8 m-4'>
                <div className='text-emerald-400 text-lg'>专为海外华裔学子量身打造的中文学习方案</div>
                <div>
                    在学生掌握好中文基本字词的基础上，以中华优秀文化为核心， 深入探讨中华文化数千年发展中一以贯之的核心。以“天人合一”、“以人为本”、“厚德载物”和“以和为贵“为主体框架，探寻先祖中华文化和当下身处其中的海外文化之间的关系。以海纳百川的包容接纳和吸收人类优秀文化的营养。<br /><br />
                    学：<br /><br />
                    1. 中国人的祖先<br />
                    2. 中国的传统和节日<br />
                    3. 中国的先哲和典籍<br />
                    4. 中国的传统艺术<br />
                    5. 中国人的传统和娱乐<br />
                    6. 现代中国，中国与世界<br />
                </div>
            </div>
        ),
    ];
    return (
        <div>
            <div className='flex space-x-4 w-3/4 mx-auto'>

                {[1, 2, 3, 4].map((buttonIndex) => (
                    <div
                        key={`switch ${buttonIndex}`}
                        onClick={() => setActiveButton(buttonIndex)}
                        className={`rounded-lg flex-1 p-4 pb-8 ${activeButton === buttonIndex ? 'bg-gray-300 text-black' : 'bg-emerald-600 text-white'}`}>
                        {buttonTexts[buttonIndex - 1]}
                    </div>
                ))}
            </div>
            {texts[activeButton - 1]}
        </div>
    )
}

export default ButtonSwitch;