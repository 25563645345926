import React, { useEffect } from "react";

import writingHw from "../../../resources/bookshelf.png";
import bookshelf from "../../../resources/events/writing-hw.png";

import Checkmark from "../../common/Checkmark";
import ButtonSwitch from "./ButtonSwitch";

const Education = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="text-white bg-emerald-500 h-50 p-8">
        <p className="text-4xl text-center">极易中文，让中文学习极其容易</p>
        <div className="flex items-center justify-evenly">
          <div className="space-y-4 text-left p-8">
            <ul className="space-y-4 mt-4">
              <li className="flex items-center">
                <Checkmark />
                <p className="ml-4">文字与文化并重的课堂</p>
              </li>
              <li className="flex items-center">
                <Checkmark />
                <p className="ml-4">AI驱动软件，助力快速识字</p>
              </li>
              <li className="flex items-center">
                <Checkmark />
                <p className="ml-4">引导学生尽早阅读，尽多阅读，尽广阅读</p>
              </li>
            </ul>
          </div>
          <div className="space-y-4 text-left p-8">
            <ul className="space-y-4 mt-4">
              <li className="flex items-center">
                <Checkmark />
                <p className="ml-4">直通AP， 助力大学申请</p>
              </li>
              <li className="flex items-center">
                <Checkmark />
                <p className="ml-4">部件式写字，更为高效</p>
              </li>
              <li className="flex items-center">
                <Checkmark />
                <p className="ml-4">家校沟通及时通畅</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 text-center py-4">
        <div className="text-2xl text-emerald-600 py-4 font-bold">
          最高效的中文学习方式&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;专为海外华裔用心打造
        </div>
      </div>
      <div className="text-center py-4">
        <div className="text-2xl text-emerald-600 py-4 font-bold">
          在线、实体中文课
        </div>
        <ButtonSwitch />
      </div>
      <div className="bg-gray-100 text-center py-4">
        <div className="text-2xl text-emerald-600 py-4 font-bold">
          打好听说读写基础&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;后续发展无忧
        </div>
      </div>

      <div className="space-y-16 m-16">
        <div className="flex justify-center space-x-16">
          <img
            src={writingHw}
            alt="img not found"
            className="w-1/2 rounded-xl"
          ></img>
          <div className="w-1/3 space-y-4">
            <div className="text-xl font-bold text-left">写字课</div>
            <div className="text-sm text-left">
              每周两次的汉字书写课，由老师带着在线上进行，并免费开放给在校学生。我们的书写课高度总结，从字源字义、间架结构、书写特点，都会讲解。授课老师经验丰富，书写规范、笔画精准、字体美观。
              <br />
            </div>
            <div className="text-sm text-left font-bold">初级班：</div>
            <ul className="list-disc text-sm">
              <li>学习29个基础笔画书写</li>
              <li>可以正确书写简单独体字</li>
              <li>能够初步掌握汉字的起笔、行笔和收笔的知识</li>
            </ul>
            <div className="text-sm text-left font-bold">高级班：</div>
            <ul className="list-disc text-sm">
              <li>独体字的书写笔画精准到位，字体规范美观，结构大小适中</li>
              <li>
                通过部件法学习汉字常用部件，掌握偏旁部首书写要领，理解偏旁部首的字源含义
              </li>
              <li>通过对合体字的书写，掌握偏旁部首变化规律和书写要领</li>
              <li>掌握常见字的间架结构，能写出美观的常见汉字</li>
            </ul>
          </div>
        </div>
        <div className="flex justify-center space-x-16">
          <div className="w-1/3 space-y-4">
            <div className="text-xl font-bold text-left">童书领读</div>
            <div className="text-sm text-left">
              每周两次的“童书领读”开放给所有热爱中文阅读的孩子们。我们会精心挑选文质皆美的中外优秀绘本，由老师带领着学生一起在线上阅读。
              让孩子们实现尽早阅读、尽多阅读、尽广阅读，并让他们从小爱上读书。
              <br />
              <br />
              在j极易的读书活动上， 我们培养出来了一批又一批爱读书的孩子，
              辅助他们走上了中文自主阅读之路。
              <br />
              <br />
              无论您的孩子现在是否是极易中文的在校生，都欢迎加入我们。极易中文期待着与更多孩子一同开启精彩缤纷的中文阅读之旅，探索中文文学的魅力！
            </div>
          </div>
          <img
            src={bookshelf}
            alt="img not found"
            className="w-1/2 rounded-xl"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Education;
