import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./Home/Home";
import News from "./News/News";
import Education from "./Education/Education";
import AboutUs from "./AboutUs/AboutUs";
import Camp from "./Camp/Camp";
import  * as RoutePath from "./routePath";

const routes = () => {
  return (
    <Routes>
      <Route path={RoutePath.HomePath} element={<Home />} />
      <Route path={RoutePath.EducationPath} element={<Education />} />
      <Route path={RoutePath.NewsPath} element={<News />} />
      <Route path={RoutePath.AboutPath} element={<AboutUs />} />
      <Route path={RoutePath.CampPath} element={<Camp />} />
    </Routes>
  );
};

export default routes
