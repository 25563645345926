import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./components/pages/routes";
import Navbar from "./components/layout/Navbar";
import MobileNavbar from "./components/layout/MobileNavbar";
import Footer from "./components/layout/Footer";
import "./App.css";

const App = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      <MobileNavbar />
      <Routes />
      <Footer />
    </Router>
  );
};

export default App;
