import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Button, Dropdown } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import * as RoutePath from "../pages/routePath";

const { Header } = Layout;

const dropdownMenu = (
    <Menu>
        <Menu.Item key="1"><Link to={RoutePath.HomePath}>首页</Link></Menu.Item>
        <Menu.Item key="2"><Link to={RoutePath.EducationPath}>课程体系</Link></Menu.Item>
        <Menu.Item key="3"><Link to={RoutePath.NewsPath}>学校动态</Link></Menu.Item>
        <Menu.Item key="4"><Link to={RoutePath.CampPath}>夏令营</Link></Menu.Item>
        <Menu.Item key="5"><Link to={RoutePath.AboutPath}>我们的故事</Link></Menu.Item>
    </Menu>
);

const MobileNavbar = () => {
    return (
        <Layout className="layout">
            <Header className="sticky inset-x-0 top-0 z-50 bg-white shadow-md">
                <div className="flex justify-between items-center px-4 h-16">
                    <div className="flex-none">
                        <div className="text-green-500 text-2xl whitespace-nowrap overflow-hidden text-overflow-ellipsis">
                            极易中文
                        </div>
                        <div className="text-gray-400 text-lg whitespace-nowrap overflow-hidden text-overflow-ellipsis">
                            GE CHINESE
                        </div>
                    </div>
                    <div className="flex-grow hidden md:flex">
                        <Menu mode="horizontal" theme="light" className="w-full justify-end">
                            <Menu.Item key="1">
                                <Link to={RoutePath.HomePath} className="text-base">首页</Link>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Link to={RoutePath.EducationPath} className="text-base">课程体系</Link>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <Link to={RoutePath.NewsPath} className="text-base">学校动态</Link>
                            </Menu.Item>
                            <Menu.Item key="4">
                                <Link to={RoutePath.CampPath} className="text-base">夏令营</Link>
                            </Menu.Item>
                            <Menu.Item key="5">
                                <Link to={RoutePath.AboutPath} className="text-base">我们的故事</Link>
                            </Menu.Item>
                        </Menu>
                    </div>
                    <Dropdown overlay={dropdownMenu} trigger={['click']} className="md:hidden">
                        <Button type="link" onClick={e => e.preventDefault()}>
                            <MenuOutlined />
                        </Button>
                    </Dropdown>
                </div>
            </Header>
        </Layout>
    );
};

export default MobileNavbar;
