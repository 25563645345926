import React from "react";
import Checkmark from "../../common/Checkmark";

const Introduction = () => {
  return (
    <div className="space-y-4 text-left p-8 text-white">
      <div className="text-4xl">极易中文</div>
      <p className="text-3xl">最高效的海外中文学习体系</p>
      <div className="flex items-center">
        <div className="mt-3 mb-3">在线、实体中文培训</div>
        <div className="ml-4 mt-3 mb-3 bg-emerald-600 rounded-br-2xl rounded-tl-2xl p-1">
          4-16岁
        </div>
      </div>
      <ul className="space-y-4 mt-4">
        <li className="flex items-center">
          <Checkmark />
          <p className="ml-4">先进的教育理念</p>
        </li>
        <li className="flex items-center">
          <Checkmark />
          <p className="ml-4">完善的教育理念</p>
        </li>
        <li className="flex items-center">
          <Checkmark />
          <p className="ml-4">优秀的教师团队</p>
        </li>
        <li className="flex items-center">
          <Checkmark />
          <p className="ml-4">及时的作业反馈</p>
        </li>
        <li className="flex items-center">
          <Checkmark />
          <p className="ml-4">有趣且高效的学习效果</p>
        </li>
      </ul>
    </div>
  );
};

export default Introduction;
