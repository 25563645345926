import React, { useEffect } from "react";

import Checkmark from "../../common/Checkmark";
import teacherGe from "../../../resources/teachers/葛老师——.jpg";
import teacherWang from "../../../resources/teachers/王老师——.jpg";
import meeting from "../../../resources/school/teacher-meeting.jpg";
import timeline from "../../../resources/timeline.svg";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="text-white text-center text-2xl bc bg-cover bg-center items-center space-y-4 p-16">
        <ul className="space-y-4">
          <div>
            <span className="bg-emerald-400 bg-opacity-50 p-1">
              曾经，我们想要学好英文来到海外，
            </span>
          </div>
          <div>
            <span className="bg-emerald-400 bg-opacity-50 p-1">
              看到了更广阔的世界。
            </span>
          </div>
          <div>
            <span className="bg-emerald-400 bg-opacity-50 p-1">
              今天，我们要教你们学好中文，
            </span>
          </div>
          <div>
            <span className="bg-emerald-400 bg-opacity-50 p-1">
              不仅为了让你们有更广阔的未来，
            </span>
          </div>
          <div>
            <span className="bg-emerald-400 bg-opacity-50 p-1">
              更要让你们成为有根的孩子。
            </span>
          </div>
        </ul>
      </div>
      <div className="bg-gray-100 text-center py-4 space-y-20">
        <div className="text-2xl text-emerald-600 py-4 font-bold">
          联合创始人
        </div>
        <div className="flex justify-center text-left">
          <img
            src={teacherGe}
            alt="img not found"
            className="w-1/6 rounded-full"
          />
          <div className="w-2/5 ml-32">
            <div className="text-lg font-bold">葛子昂</div>
            <div className="mt-4">
              曾任威盛电子芯片开发经理，美国微软的高级研发工程师和经理。
              在创立极易中文后，他把他全部的热情和关注倾注到了教学和与教学相关的软件开发中。 
            </div>
          </div>
        </div>
        <div className="flex justify-center text-left">
          <img
            src={teacherWang}
            alt="img not found"
            className="w-1/6 rounded-full"
          />
          <div className="w-2/5 ml-32">
            <div className="text-lg font-bold">王鸽鸽</div>
            <div className="mt-4">
              曾任UBI
              Soft上海工作室游戏制作人，美国微软高级项目经理。在加入极易中文后，致力于关注每位孩子，
              激发学生内在潜能，面向未来，培养人才，让他们能够成为最好的自己。
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-4 space-y-8">
        <div className="text-2xl text-emerald-600 py-4 font-bold">
          最懂海外华裔的教师团队
        </div>
        <div>
          我们是一支充满热情和专业素养的团队，致力于为每位学生提供卓越的教育。
        </div>
        <div className="flex justify-center space-x-20">
          <ul className="w-1/3 space-y-4 text-left">
            <li className="flex">
              <Checkmark />
              <div className="ml-4">
                <div className="font-bold text-md">
                  立足北美，理解痛点
                  <br />
                </div>
                <div className="text-sm font-normal">
                  深刻理解家庭和学生的需求，明白学生的痛点，
                  <br />
                  努力为学生提供最好的教育支持
                </div>
              </div>
            </li>
            <li className="flex">
              <Checkmark />
              <div className="ml-4">
                <div className="font-bold text-md">
                  丰富的教学经验
                  <br />
                </div>
                <div className="text-sm font-normal">
                  根据学生的不同需求和水平来制定教学计划，
                  <br />
                  确保每位学生都能够充分发挥潜力
                </div>
              </div>
            </li>
            <li className="flex">
              <Checkmark />
              <div className="ml-4">
                <div className="font-bold text-md">
                  优秀的教育背景
                  <br />
                </div>
                <div className="text-sm font-normal">
                  将最前沿的教育方法和理念引入课堂，确保学生获得最新、
                  <br />
                  最有效的教育 
                </div>
              </div>
            </li>
            <li className="flex">
              <Checkmark />
              <div className="ml-4">
                <div className="font-bold text-md">
                  无比热爱中国文化的心
                  <br />
                </div>
                <div className="text-sm font-normal">
                  热衷于传承和推广中国文化，
                  <br />
                  通过教育来传达对中国传统的深切热爱
                </div>
              </div>
            </li>
          </ul>
          <img src={meeting} alt="img not found" className="w-1/3 rounded-xl" />
        </div>
      </div>
      <img src={timeline} alt="img not found" className="w-full h-2/3" />
    </div>
  );
};

export default AboutUs;
