import React from "react";

type ImageCardProps = {
  src: string;
  alt?: string;
  children: React.ReactNode;
};

const ImageCard: React.FC<ImageCardProps> = ({ src, alt, children }) => {
  return (
    <div className="rounded-xl bg-gray-100 overflow-hidden m-8">
      <img className="w-full" src={src} alt={alt} />
      <div className="text-lg my-20">{children}</div>
    </div>
  );
};

export default ImageCard;
