import React, { ChangeEvent, FormEvent, useState } from "react";

type Errors = {
  phoneNumber?: string;
  email?: string;
  wechatID?: string;
};

const Form = () => {
  const [formData, setFormData] = useState({
    phoneNumber: "",
    email: "",
    wechatID: "",
  });

  const [errors, setErrors] = useState<Errors>({});

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    // You can implement your phone number validation logic here
    // For example, you can use regular expressions to check the format
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
      phoneNumber
    );
  };

  const validateEmail = (email: string) => {
    // You can implement your email validation logic here
    // For example, you can use a regular expression for email validation
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(email);
  };

  const validateWechatID = (wechatID: string) => {
    // You can implement your WeChat ID validation logic here
    // For example, check if it meets your desired format
    return /^[a-zA-Z0-9_-]+$/.test(wechatID);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validation logic
    const newErrors: Errors = {};

    if (!validatePhoneNumber(formData.phoneNumber)) {
      newErrors.phoneNumber = "Invalid phone number format";
    }

    if (!validateEmail(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    if (!validateWechatID(formData.wechatID)) {
      newErrors.wechatID = "Invalid WeChat ID format";
    }

    // If there are errors, set them in state and prevent form submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Submit the form or perform other actions here
      // You can handle form submission logic when all inputs are valid
      // For example, send data to a server
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ul className="space-y-2">
        <li>
          <input
            className={`shadow appearance-none border rounded w-4/5 py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline ${
              errors.phoneNumber ? "border-red-500" : ""
            }`}
            name="phoneNumber"
            type="text"
            placeholder="请输入您的手机号"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          {errors.phoneNumber && (
            <p className="text-red-500">{errors.phoneNumber}</p>
          )}
        </li>
        <li>
          <input
            className={`shadow appearance-none border rounded w-4/5 py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline ${
              errors.email ? "border-red-500" : ""
            }`}
            name="email"
            type="text"
            placeholder="请输入您的邮箱地址"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <p className="text-red-500">{errors.email}</p>}
        </li>
        <li>
          <input
            className={`shadow appearance-none border rounded w-4/5 py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline ${
              errors.wechatID ? "border-red-500" : ""
            }`}
            name="wechatID"
            type="text"
            placeholder="请输入您的微信ID"
            value={formData.wechatID}
            onChange={handleChange}
          />
          {errors.wechatID && <p className="text-red-500">{errors.wechatID}</p>}
        </li>
        <li>
          <div className="mx-auto my-4 text-center bg-orange-400 py-1 w-4/5 rounded-full text-white">
            <button type="submit">立即预约</button>
          </div>
        </li>
      </ul>
    </form>
  );
};

export default Form;
