import React, { useEffect } from "react";

import grad from "../../../resources/certificates/grad.png";
import people from "../../../resources/camps/8-people.png";
import words from "../../../resources/assets/words.png";
import fu from "../../../resources/events/fu.png";
import stickers from "../../../resources/events/stickers.png";
import zongzi from "../../../resources/events/zongzi.png";
import lanterns from "../../../resources/events/lanterns.png";
import dumpling from "../../../resources/events/dumpling.png";
import show from "../../../resources/events/show.png";
import costumes from "../../../resources/events/costumes.jpg";

const News = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="text-white contest bg-cover bg-center items-center space-y-4 pt-20 pb-40">
        <p className="text-3xl text-center">
          传承中华文化精粹&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;促进传统文化体验
        </p>
        <p className="text-3xl text-center">
          激发中文学习兴趣&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;促进文化交流互动
        </p>
        "
        <div className="text-center text-lg">
          极易中文开展各类特色活动，通过实践和体验能够助力学生们更加融入 <br />
          中华文化之中，让他们真切感受到学习中文的无尽魅力。
        </div>
      </div>
      <div className="text-center py-4 space-y-20">
        <div className="text-2xl text-emerald-600 py-4 font-bold">文化活动</div>
        <div className="flex justify-center space-x-16">
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-right m-4">
              “我的暑假生活”作文竞赛
            </div>
            <div className="text-md text-left">
              作文大赛一经推出，就收到了学生们的积极响应。这些生活在海外的学子们，他们的暑假生活也是异常丰富。孩子们的文章有游记、也有日记；有去亚洲的、也有去欧洲的；有坐船的、也有看飞机的；有爬山的、也有参观博物馆的；有”到了长城当好汉“的、也有”发现旅途中错误的英文翻译“的……
              <br />
              <br />
              我们惊喜地看到，莘莘学子们用自己童真的笔触、新奇的视角、独特的位置，描写和记录了他们丰富多采的暑假生活。
            </div>
          </div>
          <img
            src={grad}
            alt="img not found"
            className="w-1/3 rounded-xl"
          ></img>
        </div>
        <div className="flex justify-center space-x-16">
          <img
            src={people}
            alt="img not found"
            className="w-1/3 rounded-xl"
          ></img>
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-left my-4">
              全球华语朗诵大赛
            </div>
            <div className="text-md text-left">
              作为一项由中国华文教育基金会组织的全球华文朗诵顶级赛事，极易中文学校自2022年参赛以来，年年获得全球金奖。{" "}
              <br />
              <br />
              我们的学生用自己自信的声音向全世界传达了新生代华裔青少年热爱和平、知学敢为、自强不息的精神风貌。
            </div>
          </div>
        </div>
        <div className="flex justify-center space-x-16">
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-right my-4">
              “一笔一画中国字”， 仓颉杯汉字大赛
            </div>
            <div className="text-md text-left">
              独特的汉字文化，蕴含着丰富的智慧。“仓颉杯”汉字大赛让同学们走进汉字文化：传承文化根脉，领略千年智慧。
              <br />
              <br />
              在2022年仓颉杯汉字大赛中， 极易中文学校取得了全球总亚军两名，
              全球总季军一名的好成绩。 
            </div>
          </div>
          <img
            src={words}
            alt="img not found"
            className="w-1/3 rounded-xl"
          ></img>
        </div>
        <div className="flex justify-center space-x-16">
          <img src={fu} alt="img not found" className="w-1/3 rounded-xl"></img>
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-left my-4">
              “除夕写‘福’字
            </div>
            <div className="text-md text-left">
              “过新年、贴福字”，这也预示着一年的好光景。
              <br />
              <br />
              孩子们跟着大书法家一起写“福”字，把中华民族优秀的传统文化传扬到海外。 
            </div>
          </div>
        </div>
        <div className="flex justify-center space-x-16">
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-right my-4">
              正月十五猜灯谜
            </div>
            <div className="text-md text-right">
              这一天里，人们会里闹花灯、吃元宵，当然也离不了启迪智慧的猜灯谜活动。
              <br />
              <br />
              难上又难的字谜，你又猜对了几个呢？
            </div>
          </div>
          <img
            src={stickers}
            alt="img not found"
            className="w-1/3 rounded-xl"
          ></img>
        </div>
        <div className="flex justify-center space-x-16">
          <img
            src={zongzi}
            alt="img not found"
            className="w-1/3 rounded-xl"
          ></img>
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-left my-4">
              浓情端午，粽香满园
            </div>
            <div className="text-md text-left">
              五月五，是端阳； 门插艾，香满堂。吃粽子，撒白糖； 赛龙舟，喜洋洋。
              <br />
              <br />
              独特的端午习俗和包粽子活动，
              激发起了海外学子们对中国传统文化的浓厚兴趣。
            </div>
          </div>
        </div>
        <div className="flex justify-center space-x-16">
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-right my-4">
              中秋汉服，诗词游园
            </div>
            <div className="text-md text-right">
              汉服游园，礼乐大会，
              <br />
              置茶赏月这一天，
              <br />
              让我们长襟连袂，衣裙翩跹，
              <br />
              连灯垂影，华彩满天，
              <br />
              以诗会友，把手言欢。
            </div>
          </div>
          <img
            src={lanterns}
            alt="img not found"
            className="w-1/3 rounded-xl"
          ></img>
        </div>
        <div className="flex justify-center space-x-16">
          <img
            src={dumpling}
            alt="img not found"
            className="w-1/3 rounded-xl"
          ></img>
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-left my-4">
              冬至包饺子{" "}
            </div>
            <div className="text-md text-left">
              包饺子、吃饺子、学传统、扫房子。
              <br />
              <br />
              都说“冬至大如年”。在这个一年中白天最短的冬日，用一碗热气腾腾的饺子来驱散严寒吧。 
            </div>
          </div>
        </div>
        <div className="flex justify-center space-x-16">
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-right my-4">
              合唱表演
            </div>
            <div className="text-md text-left">
              音乐的魅力是如此之大， 孩子们通过音乐感受文化，
              享受中文。孩子们在演唱小虎队的歌曲《再聚首》和王菲的《明月几时有》。
            </div>
          </div>
          <img
            src={show}
            alt="img not found"
            className="w-1/3 rounded-xl"
          ></img>
        </div>
        <div className="flex justify-center space-x-16">
          <img
            src={costumes}
            alt="img not found"
            className="w-1/3 rounded-xl"
          ></img>
          <div className="w-1/3">
            <div className="text-lg text-emerald-500 font-bold text-left my-4">
              话剧表演
            </div>
            <div className="text-md text-left">
              同学们在表演话剧 《小蝌蚪找妈妈》
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
