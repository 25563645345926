import React from 'react';

const Checkmark = () => {
    return (
        <svg className="h-6 w-6 flex-none fill-green-100 stroke-emerald-500 stroke-2" stroke-linecap="stroke" stroke-linejoin="round">
            <circle cx="12" cy="12" r="11" />
            <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
        </svg>
    )
}

export default Checkmark;